// import store
import store from "../store/index";

// GET api/v1/alert/read/:alertId
const alertRead = async (alertId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/alert/read/${alertId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateAlertCache", {
                alert: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateAlertCache", {
                alert: null
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateAlertCache", {
            alert: null
        });
    }
};
// GET api/v1/alert/readall
const alertReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/alert/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateAlertCache", {
                alertList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateAlertCache", {
                alertList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateAlertCache", {
            alertList: []
        });
    }
};
// POST api/v1/alert/dismiss/:alertId
const alertDismiss = async (alertId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/alert/dismiss/${alertId}`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

// export
export {
    alertRead,
    alertReadAll,
    alertDismiss
};