
// import vue-property-decorator
import { Component, Vue } from 'vue-property-decorator';

// import apiClient
import { alertDismiss, alertReadAll } from "../../apiClient/alertEndpoints";

// import CONSTANTS
import { CONSTANTS } from "../../apiClient/constants";

@Component
export default class AlertList extends Vue {

    // CONSTANTS
    private CONSTANTS = CONSTANTS;

    // LOADING
    private isLoading: boolean = false;
    private hasLoaded: boolean = false;

    // BUTTON
    private btnLoad?: HTMLButtonElement;

    // MOUNTED
    public mounted() {
        console.log("mounted()");

        this.hasLoaded = false;
        this.btnLoad = <HTMLButtonElement> document.getElementById("btnLoad");

        this.readAllAlerts();
    };

    // BUTTON BINDS
    public async readAllAlerts() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            await alertReadAll();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    };

    public async alertDismiss(alertId: number) {
        await alertDismiss(alertId);
        await alertReadAll();
    }

};
