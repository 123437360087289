
import { Component, Vue } from 'vue-property-decorator';

// import components
import AlertList from '../components/alert/AlertList.vue';

@Component({
    components: {
        AlertList,
    },
})
export default class AlertPage extends Vue {};
